<template>
  <div class="cloudHis">
    <HeadView classA="1" headBg="4"></HeadView>
    <swiper :options="swiperOption" ref="mySwiper" class="swiper-no-swiping">
      <swiper-slide>
        <div class="swiperFirstWrap">
          <div class="swiperFirst">
            <h3><strong>致医</strong>云HIS</h3>
            <p>
              更智能的
              <strong>云HIS</strong>
            </p>
            <p class="text">为基层中小医院打造全方位诊疗管理系统</p>
            <div @click="showJoin" class="trialBtn">点击申请试用</div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="cloudClinicFunctionWrap">
      <div class="cloudClinicFunction">
        <h3>致医云HIS方案价值</h3>
        <ul class="functionMain">
          <li>
            <img alt="" src="../../assets/images/cloudHIS/fun1.png" />
            <dl>
              <dt>医疗</dt>
              <dd>改善医疗环境</dd>
              <dd>提高工作效率</dd>
              <dd>规范诊疗过程</dd>
              <dd>防范医疗风险</dd>
            </dl>
          </li>
          <li>
            <img alt="" src="../../assets/images/cloudHIS/fun2.png" />
            <dl>
              <dt>运营</dt>
              <dd>科学运营管理</dd>
              <dd>精细管理</dd>
              <dd>质量监控</dd>
              <dd>经营分析</dd>
            </dl>
          </li>
          <li>
            <img alt="" src="../../assets/images/cloudHIS/fun3.png" />
            <dl>
              <dt>费用</dt>
              <dd>有效成本管控</dd>
              <dd>减少IT投入</dd>
              <dd>降低运维成本</dd>
              <dd>减少二开成本</dd>
            </dl>
          </li>
          <li>
            <img alt="" src="../../assets/images/cloudHIS/fun4.png" />
            <dl>
              <dt>患者</dt>
              <dd>提高就医体验</dd>
              <dd>打造线上管理</dd>
              <dd>增加会员粘性</dd>
              <dd>提升顾客体验</dd>
            </dl>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="cloudClinicTop">
        <h3>致医云HIS功能</h3>
        <div class="swiperTop">
          <ul class="tabTitle">
            <li @click="tabId = 0" :class="tabId === 0 ? 'active' : ''">
              便民服务
            </li>
            <li @click="tabId = 1" :class="tabId === 1 ? 'active' : ''">
              医疗服务
            </li>
            <li @click="tabId = 2" :class="tabId === 2 ? 'active' : ''">
              药事服务
            </li>
            <li @click="tabId = 3" :class="tabId === 3 ? 'active' : ''">
              资源服务
            </li>
          </ul>
          <div class="tabBox">
            <div class="tabCon" v-show="tabId === 0">
              <ul class="tabLeft tab0Left">
                <li>分时预约</li>
                <li>自助查询</li>
                <li>用药提醒</li>
                <li>随访满意度</li>
              </ul>
              <div class="tabRight tab0Right">
                <img
                  alt=""
                  src="../../assets/images/cloudHIS/tab1.png"
                  width="691"
                />
              </div>
            </div>
            <div class="tabCon" v-show="tabId === 1">
              <ul class="tabLeft tab1Left">
                <li>病例诊断</li>
                <li>医嘱处方</li>
                <li>模版分配</li>
                <li>中医诊断</li>
              </ul>
              <div class="tabRight tab1Right">
                <img
                  alt=""
                  src="../../assets/images/cloudHIS/tab2.png"
                  width="691"
                />
              </div>
            </div>
            <div class="tabCon" v-show="tabId === 2">
              <ul class="tabLeft tab2Left">
                <li>药品采购</li>
                <li>库存管理</li>
                <li>价格管理</li>
                <li>库房盘点</li>
              </ul>
              <div class="tabRight tab2Right">
                <img
                  alt=""
                  src="../../assets/images/cloudHIS/tab3.png"
                  width="691"
                />
              </div>
            </div>
            <div class="tabCon" v-show="tabId === 3">
              <ul class="tabLeft tab3Left">
                <li>连锁集团</li>
                <li>多点执业</li>
                <li>医师档案</li>
                <li>值班考勤</li>
              </ul>
              <div class="tabRight tab3Right">
                <img
                  alt=""
                  src="../../assets/images/cloudHIS/tab4.png"
                  width="691"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cloudHisAdvantageWrap">
      <div class="cloudHisAdvantage">
        <h3>产品优势</h3>
        <div class="cloudHisAdvantageCon">
          <dl class="advantagePatient">
            <dt>
              患者
              <br />使用
            </dt>
            <dd>
              <img alt="" src="../../assets/images/cloudHIS/advantage2.png" />
              <span>公众号</span>
            </dd>
            <dd>
              <img alt="" src="../../assets/images/cloudHIS/advantage3.png" />
              <span>小程序</span>
            </dd>
          </dl>
          <dl class="advantageDoctor">
            <dt>
              医生
              <br />使用
            </dt>
            <dd>
              <img alt="" src="../../assets/images/cloudHIS/advantage5.png" />
              <span>一体机</span>
            </dd>
            <dd>
              <img alt="" src="../../assets/images/cloudHIS/advantage6.png" />
              <span>IPAD</span>
            </dd>
            <dd>
              <img alt="" src="../../assets/images/cloudHIS/advantage7.png" />
              <span>windows</span>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="cloudClinicBottomWrap">
      <div class="cloudClinicBottom">
        <h3>他们都在用致医云HIS</h3>
        <swiper :options="swiperOption2" ref="mySwiper2">
          <!-- slides -->
          <swiper-slide>
            <div class="clinicWrap">
              <img alt="" src="../../assets/images/cloudHIS/clinic2.png" />
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="clinicWrap">
              <img alt="" src="../../assets/images/cloudHIS/clinic3.png" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="clinicWrap">
              <img alt="" src="../../assets/images/cloudHIS/clinic1.png" />
            </div>
          </swiper-slide>

          <!-- Optional controls -->
          <!-- 如果需要导航按钮 -->
          <div
            class="swiper-button-prev swiper-button-prev2"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next2"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </div>
    <Foot></Foot>
    <JoinBox
      ref="joinBox"
      :companyValid="companyValidVal"
      companyText="医疗机构名称（必填）"
      type="2"
      title="致医云HIS申请试用"
    ></JoinBox>
  </div>
</template>
<style scoped>
@import "../../assets/style/hardware.css";
</style>
<style lang="scss" scoped>
.cloudHis {
  .swiperFirstWrap {
    width: 100%;
    height: 550px;
    background: url("../../assets/images/cloudHIS/bannerbg2x.png") no-repeat;
    background-size: cover;
    .swiperFirst {
      width: 79.17%;
      height: 100%;
      max-width: 1140px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 100px;
      box-sizing: border-box;
      h3 {
        font-size: 36px;
        color: rgba(255, 255, 255, 1);
        line-height: 50px;
        border-bottom: 1px solid #fff;
        strong {
          font-weight: bold;
        }
      }
      p {
        font-size: 40px;
        color: rgba(255, 255, 255, 1);
        line-height: 56px;
        padding: 10px 0 30px 0;
        strong {
          font-weight: bold;
        }
      }
      p.text {
        font-size: 24px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 33px;
      }
      .trialBtn {
        width: 152px;
        height: 52px;
        line-height: 52px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        background: rgba(0, 213, 224, 1);
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .cloudClinicFunctionWrap {
    width: 100%;
    background: #fff;
    .cloudClinicFunction {
      width: 79.17%;
      max-width: 1140px;
      margin: 0 auto;
      padding-bottom: 50px;
      h3 {
        padding: 60px 0;
        font-size: 28px;
        color: #1a1c1f;
        font-weight: bold;
      }
      .functionMain {
        display: flex;
        justify-content: space-around;
        li {
          height: 100%;
          position: relative;
          width: 24%;
          overflow: hidden;
          border-radius: 10px;
          img {
            width: 100%;
            -ms-transition: 0.6s ease-in;
            -moz-transition: 0.6s ease-in;
            -o-transition: 0.6s ease-in;
            -webkit-transition: 0.6s ease-in;
            transition: 0.6s ease-in;
          }
          dl {
            width: 100%;
            height: 100%;
            position: absolute;
            font-size: 20px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            dt {
              font-size: 22px;
              font-weight: 600;
              color: rgba(255, 255, 255, 1);
              line-height: 30px;
              padding-bottom: 18px;
              position: relative;
              margin-bottom: 13px;
            }
            dt::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 8px;
              background: rgba(0, 213, 224, 1);
              border-radius: 4px;
              bottom: 0;
              left: 0;
            }
            dd {
              font-size: 18px;
              font-weight: 400;
              color: rgba(255, 255, 255, 1);
              line-height: 25px;
              padding-bottom: 10px;
            }
          }
        }
        li:hover {
          img {
            -ms-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -o-transform: scale(1.1);
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .main {
    width: 79.17%;
    max-width: 1140px;
    margin: 0 auto;
    .cloudClinicTop {
      h3 {
        padding: 60px 0;
        font-size: 28px;
        color: #1a1c1f;
        font-weight: bold;
      }
      .tabTitle {
        display: flex;
        justify-content: space-between;
        li {
          border: 1px solid #ccc;
          height: 30px;
          line-height: 30px;
          width: 25%;
          text-align: center;
          color: #1a1c1f;
          font-size: 24px;
          padding: 11px 0;
          cursor: pointer;
        }
        li.active {
          border: 1px solid #00d5e0;
          color: #00d5e0;
        }
      }
      .tabBox {
        padding: 80px 0;
        .tabCon {
          display: flex;
          justify-content: space-around;
          align-items: center;
          .tabLeft {
            width: 40%;
            padding-right: 30px;
            box-sizing: border-box;
            li {
              color: #1a1c1f;
              font-size: 20px;
              font-weight: 400;
              line-height: 48px;
              width: 221px;
              height: 48px;
              background: rgba(255, 255, 255, 1);
              box-shadow: 0px 0px 10px 0px rgba(232, 232, 232, 0.5);
              border-radius: 4px;
              margin: 0 auto 10px auto;
            }
          }
          .tabRight {
            width: 60%;
            img {
              box-shadow: 0 20px 50px 0 rgba(216, 216, 216, 0.7);
            }
          }
        }
      }
    }
  }
  .cloudHisAdvantageWrap {
    width: 100%;
    background: #fff;
    padding-bottom: 70px;
    .cloudHisAdvantage {
      width: 79.17%;
      max-width: 1140px;
      margin: 0 auto;
      h3 {
        font-size: 28px;
        color: #1a1c1f;
        padding: 50px 0 56px 0;
        font-weight: bold;
      }
      .cloudHisAdvantageCon {
        display: flex;
        justify-content: space-between;
        dl {
          display: flex;
          justify-content: space-between;
          dt {
            width: 102px;
            height: 102px;
            background: #00ccc3;
            border-radius: 51px;
            font-size: 18px;
            color: #fff;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          dt::after {
            content: "";
            position: absolute;
            top: -7px;
            left: 70px;
          }

          dd {
            width: 102px;
            height: 102px;
            background: #eff8f8;
            border-radius: 51px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            span {
              display: block;
              font-size: 17px;
              color: #1a1c1f;
              padding-top: 6px;
            }
          }
        }
        dl.advantagePatient {
          width: 39%;
          margin-right: 9%;
          dt::after {
            width: 38px;
            height: 38px;
            background: url("../../assets/images/cloudHIS/advantage1.png")
              no-repeat;
            background-size: 100%;
          }
        }
        dl.advantageDoctor {
          width: 52%;
          dt::after {
            width: 39px;
            height: 38px;
            background: url("../../assets/images/cloudHIS/advantage4.png")
              no-repeat;
            background-size: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .main,
    .cloudClinicFunctionWrap .cloudClinicFunction,
    .cloudClinicBottom {
      width: 90%;
    }
  }
  @media screen and (max-width: 1024px) {
    .swiperFirstWrap {
      height: 320px;
      .swiperFirst {
        padding-left: 0;
        h3 {
          font-size: 26px;
          line-height: 50px;
        }
        p {
          font-size: 30px;
          line-height: 56px;
          padding: 0;
        }
        .trialBtn {
          margin-top: 15px;
        }
      }
    }
    .main,
    .cloudClinicFunctionWrap .cloudClinicFunction,
    .cloudClinicBottom {
      width: 94%;
    }
    .main {
      .cloudClinicTop {
        .tabBox {
          .tabCon {
            .tabLeft {
              li {
                font-size: 16px;
                line-height: 36px;
                width: 180px;
                height: 36px;
              }
            }
            .tabRight {
              width: 60%;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .swiperFirstWrap {
      height: 260px;
      .swiperFirst {
        width: 94%;
        padding-left: 0;
        padding-top: 30px;
        h3 {
          font-size: 20px;
          line-height: 30px;
        }
        p {
          font-size: 26px;
          line-height: 36px;
          padding: 0;
        }
        .trialBtn {
          margin-top: 5px;
        }
      }
    }
    .main {
      .cloudClinicTop {
        .tabBox {
          .tabCon {
            .tabLeft {
              li {
                font-size: 14px;
                line-height: 30px;
                width: 140px;
                height: 30px;
              }
            }
            .tabRight {
              img {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
    .cloudClinicFunctionWrap {
      .cloudClinicFunction {
        width: 100%;
        max-width: 100%;
      }
    }
    .cloudHisAdvantageWrap {
      .cloudHisAdvantage {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  @media screen and (max-width: 668px) {
    .cloudClinicFunctionWrap .cloudClinicFunction .functionMain {
      flex-wrap: wrap;
    }
    .cloudClinicFunctionWrap .cloudClinicFunction .functionMain li {
      width: 46%;
      margin-bottom: 30px;
    }
    .cloudHisAdvantageWrap .cloudHisAdvantage .cloudHisAdvantageCon {
      flex-wrap: wrap;
    }
    .cloudHisAdvantageWrap
      .cloudHisAdvantage
      .cloudHisAdvantageCon
      dl.advantagePatient,
    .cloudHisAdvantageWrap
      .cloudHisAdvantage
      .cloudHisAdvantageCon
      dl.advantageDoctor {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
      justify-content: space-around;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import Foot from "@/components/common/Foot";
import HeadView from "@/components/common/Head";
import JoinBox from "@/components/common/JoinBox";

export default {
  name: "home",
  components: {
    HeadView,
    Foot,
    JoinBox,
  },
  data() {
    return {
      companyValidVal: true,
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 允许点击小圆点跳转
        },
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false // 手动切换之后继续自动轮播
        // },
        autoplay: false,
        loop: true,
      },
      tabId: 0,
      swiperOption2: {
        navigation: {
          nextEl: ".swiper-button-next2",
          prevEl: ".swiper-button-prev2",
        },
        autoplay: false,
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false // 手动切换之后继续自动轮播
        // },
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination2",
          clickable: true,
        },
      },
    };
  },
  methods: {
    tab(index) {
      this.num = index;
    },
    showJoin() {
      this.$refs.joinBox.isShowDialog = true;
    },
  },
  computed: {},
  mounted() {},
};
</script>
